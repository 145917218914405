import { NoSsr } from '@material-ui/core';
import React from 'react';
import MediaQuery from 'react-responsive';
import Layout from '../components/Layout';
import ResourceSlider from '../components/ResourceSlider';

const resources = [
  {
    title: "Innover n'est plus une option c'est une question de survie",
    videos: [
      {
        video: 'https://www.youtube-nocookie.com/embed/7HvPtf2Dh9k',
        thumbnail: 'https://img.youtube.com/vi/7HvPtf2Dh9k/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/kSpufRBL8xA',
        thumbnail: 'https://img.youtube.com/vi/kSpufRBL8xA/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/ynQpWEhkQlU',
        thumbnail: 'https://img.youtube.com/vi/ynQpWEhkQlU/sddefault.jpg',
      },
    ],
  },
  {
    title: "Repensons ensemble le management de l'innovation",
    videos: [
      {
        video: 'https://www.youtube-nocookie.com/embed/sDnGsShT-NU',
        thumbnail: 'https://img.youtube.com/vi/sDnGsShT-NU/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/RUfhIcpnwaA',
        thumbnail: 'https://img.youtube.com/vi/RUfhIcpnwaA/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/Tq_Gl1lbSiU',
        thumbnail: 'https://img.youtube.com/vi/Tq_Gl1lbSiU/sddefault.jpg',
      },
    ],
  },
  {
    title: 'Faire émerger des idées est un début mais pas une fin en soi',
    videos: [
      {
        video: 'https://www.youtube-nocookie.com/embed/Ry-c0FerfAQ',
        thumbnail: 'https://img.youtube.com/vi/Ry-c0FerfAQ/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/6kcol50mI0s',
        thumbnail: 'https://img.youtube.com/vi/6kcol50mI0s/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/GezVgV-jNJU',
        thumbnail: 'https://img.youtube.com/vi/GezVgV-jNJU/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/-IlaDIfKAzg',
        thumbnail: 'https://img.youtube.com/vi/-IlaDIfKAzg/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/U2tbayJYqrg',
        thumbnail: 'https://img.youtube.com/vi/U2tbayJYqrg/sddefault.jpg',
      },
    ],
  },
  {
    title: 'Mettez en mouvement tous vos salariés pour assurer votre survie',
    videos: [
      {
        video: 'https://www.youtube-nocookie.com/embed/ai51ipQlf2w',
        thumbnail: 'https://img.youtube.com/vi/ai51ipQlf2w/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/FfVNu0HI8pY',
        thumbnail: 'https://img.youtube.com/vi/FfVNu0HI8pY/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/7NPt822GtTU',
        thumbnail: 'https://img.youtube.com/vi/7NPt822GtTU/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/BmVySkzpYuQ',
        thumbnail: 'https://img.youtube.com/vi/BmVySkzpYuQ/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/OgG5LgOslTQ',
        thumbnail: 'https://img.youtube.com/vi/OgG5LgOslTQ/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/bV5kxm0RqKg',
        thumbnail: 'https://img.youtube.com/vi/bV5kxm0RqKg/sddefault.jpg',
      },
    ],
  },
  {
    title: "Campus n'est pas une solution digitale de plus sur le marché",
    videos: [
      {
        video: 'https://www.youtube-nocookie.com/embed/LHrDQdBom6M',
        thumbnail: 'https://img.youtube.com/vi/LHrDQdBom6M/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/ULHutit8C_A',
        thumbnail: 'https://img.youtube.com/vi/ULHutit8C_A/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/91cw5Vto510',
        thumbnail: 'https://img.youtube.com/vi/91cw5Vto510/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/0CQWK-Fcs5w',
        thumbnail: 'https://img.youtube.com/vi/0CQWK-Fcs5w/sddefault.jpg',
      },
      {
        video: 'https://www.youtube-nocookie.com/embed/C5FFg_zWn-o',
        thumbnail: 'https://img.youtube.com/vi/C5FFg_zWn-o/sddefault.jpg',
      },
    ],
  },
];

class Resources extends React.Component {
  public render() {
    return (
      <Layout>
        <NoSsr>
          <MediaQuery minWidth="960px">
            {matches => {
              return (
                <>
                  {resources.map((r, i) => (
                    <div style={{ marginTop: i === 0 ? '50px' : '30px' }}>
                      <ResourceSlider
                        arrowPos={matches ? 60 : 0}
                        title={r.title}
                        count={matches ? 3 : 1}
                        videos={r.videos}
                      />
                    </div>
                  ))}
                </>
              );
            }}
          </MediaQuery>
        </NoSsr>
      </Layout>
    );
  }
}

export default Resources;
