import { Typography } from '@material-ui/core';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import VideoWithThumbnail from './VideoWithThumbnail';
import theme from '../theme';

const Title = styled(Typography)`
  &&&& {
    padding-bottom: 15px;
    font-family: Quicksand;
    color: ${theme.palette.primary.main};
    font-weight: 500;
    font-size: 26px;
    ${theme.breakpoints.down('sm')} {
      font-size: 20px;
    }
  }
`;

const Wrapper = styled.div``;

const VideoSlide = styled.div`
  position: relative;
  padding: 20px;
  padding-top: calc(9 / 16 * (100% - 40px));
  overflow: hidden;

  iframe {
    border: none;
    user-select: none;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const Dots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`;

const Dot = styled.div`
  height: 10px;
  width: 10px;
  margin: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: rgb(0, 0, 0, 0.6);
  }
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -40px;
  color: ${theme.palette.grey[400]};
  cursor: pointer;

  &:hover {
    color: ${theme.palette.grey[800]};
  }

  ${theme.breakpoints.down('sm')} {
    top: 100%;
    svg {
      font-size: 30px !important;
    }
    margin-top: -25px;
  }
`;

interface Props {
  title: string;
  // tslint:disable-next-line:array-type
  videos: { video: string; thumbnail: string }[];
  count: number;
  arrowPos: number;
}

@observer
class ResourceSlider extends React.Component<Props> {
  @observable
  public index: number = 0;

  @action
  public componentWillUpdate(nextProps: Props) {
    if (nextProps.count !== this.props.count) {
      this.index = 0;
    }
  }

  public render() {
    return (
      <div>
        <Title>{this.props.title}</Title>
        <Wrapper style={{ position: 'relative' }}>
          {this.index !== 0 && (
            <Arrow
              style={{ left: `-${this.props.arrowPos}px` }}
              onClick={() =>
                runInAction(() => {
                  this.index = this.index - 1;
                })
              }
            >
              <i className="fas fa-angle-left fa-3x" />
            </Arrow>
          )}
          {this.index < this.props.videos.length / this.props.count - 1 && (
            <Arrow
              style={{ right: `-${this.props.arrowPos}px` }}
              onClick={() =>
                runInAction(() => {
                  this.index = this.index + 1;
                })
              }
            >
              <i className="fas fa-angle-right fa-3x" />
            </Arrow>
          )}
          <SwipeableViews
            index={this.index}
            onChangeIndex={i =>
              runInAction(() => {
                this.index = i;
              })
            }
            enableMouseEvents
            style={{ marginTop: '12px', marginRight: '-20px' }}
            slideStyle={{
              width: `calc(100% / ${this.props.count})`,
              paddingRight: '20px',
            }}
          >
            {this.props.videos.map(v => (
              <VideoSlide>
                <VideoWithThumbnail video={v.video} thumbnail={v.thumbnail} />
              </VideoSlide>
            ))}
          </SwipeableViews>
          <Dots>
            {this.props.videos.map(
              (v, i) =>
                i % this.props.count === 0 ? (
                  <Dot
                    onClick={() =>
                      runInAction(() => {
                        this.index = i / this.props.count;
                      })
                    }
                    style={{
                      backgroundColor:
                        i / this.props.count === this.index
                          ? 'rgba(0, 0, 0, 0.6)'
                          : undefined,
                    }}
                  />
                ) : null,
            )}
          </Dots>
        </Wrapper>
      </div>
    );
  }
}

export default ResourceSlider;
