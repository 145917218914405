import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import YoutubeButtonDark from '../images/icons/youtube_social_icon_dark.png';
import YoutubeButton from '../images/icons/youtube_social_icon_red.png';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 2px;

  .yt-button {
    display: none;
  }

  iframe {
    display: none;
  }

  &:hover {
    .yt-button-dark {
      display: none;
    }

    .yt-button {
      display: block;
    }
  }

  &.open {
    .yt-button-dark {
      display: none;
    }

    .yt-button {
      display: none;
    }

    iframe {
      display: block;
    }
  }
`;

interface Props {
  video: string;
  thumbnail: string;
}

@observer
class VideoWithThumbnail extends React.Component<Props> {
  @observable
  public open: boolean = false;

  public render() {
    return (
      <Wrapper
        onClick={() =>
          runInAction(() => {
            this.open = true;
          })
        }
        className={this.open ? 'open' : ''}
        style={{
          backgroundImage: `url(${this.props.thumbnail})`,
        }}
      >
        <img src={YoutubeButton} className="yt-button" />
        <img src={YoutubeButtonDark} className="yt-button-dark" />
        {this.open && (
          <iframe
            src={`${
              this.props.video
            }?modestbranding=1&rel=0autoplay&autoplay=1`}
            frameBorder={0}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </Wrapper>
    );
  }
}

export default VideoWithThumbnail;
